<template>
  <div>
    <v-modal-sites-insert v-model="dialogInsert"></v-modal-sites-insert>
    <v-modal-sites-delete v-model="dialogDelete" :id="selectedIndex"></v-modal-sites-delete>
    <v-modal-sites-update v-model="dialogUpdate" :id="selectedIndex"></v-modal-sites-update>
    <v-page-title title="Sites">
        <v-btn elevation="0" color="blue-grey darken-4" small dark @click="dialogInsert = true">
          <v-icon left small>mdi-plus</v-icon>
          New Site
        </v-btn>
    </v-page-title>
    <v-page-subtitle title="">
      <v-container class="py-5" style="max-width:1160px;">
        <v-row align="center">
          <v-col>
            <v-select
              v-model="month"
              :items="months"
              item-value="id"
              item-text="label"
              hide-details
              dense
              outlined
              label="Filter by month"></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="showOnly"
              :items="showingOnlyFilter"
              item-value="id"
              item-text="label"
              hide-details
              dense
              outlined
              label="Filter by active state"
              @change="saveActiveState"></v-select>
          </v-col>
          <v-col v-show="false">
            <v-btn-toggle v-model="listType" mandatory rounded background-color="transparent">
              <v-btn small color="white" @click="listType = 0">
                <v-icon color="blue-grey darken-4">mdi-format-list-bulleted</v-icon>
              </v-btn>
              <v-btn small color="white" @click="listType = 1">
                <v-icon color="blue-grey darken-4">mdi-view-grid</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>
    </v-page-subtitle>
    <v-card max-width="1200px" class="mx-auto mb-5" elevation="0" color="transparent">
      <v-container class="pt-0">
        <v-row dense>
          <v-col>
            <v-loader v-if="!sites"></v-loader>
            <div v-else>
              <div class="hounddog-table">
                <v-data-table
                  v-if="listType == 0"
                  item-key="name"
                  :headers="headers"
                  :items="buildClientList()"
                  :items-per-page="15"
                  :item-class="getRowClass"
                  no-data-text="No sites added yet.">

                  <template v-slot:item.icon="{}">
                    <v-icon color="#CFD8DC" class="pr-2 main-icon">mdi-application-brackets-outline</v-icon>
                  </template>

                  <template v-slot:item.name="{ item }">
                    <div class="identity">
                      <div class="favicon">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              absolute
                              v-bind="attrs"
                              v-on="on"
                              @click="rerunGetFavicon(item)"
                              width="30px"
                              height="30px"
                              small
                              plain
                              text
                              class="pa-0 btn"
                              fab>
                            </v-btn>
                          </template>
                          <span>Re-run Favicon</span>
                        </v-tooltip>
                        <v-img width="30px" height="30px" :src="item.favicon" class="rounded-circle"></v-img>
                      </div>
                      <div class="name-url">
                        <b :class="{ muted: !item.active }">{{ item.name }}
                        </b><br />
                        <a :href="item.url?.startsWith('http') ? item.url : `https://${item.url}`" 
                          target="_blank" 
                          rel="noopener noreferrer"  
                          class="text-caption">
                          {{ item.url }} 
                          <v-icon x-small>mdi-open-in-new</v-icon>
                        </a>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.frequency="{ item }">
                    <span class="text-caption">{{ item.frequency }}</span>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <span class="text-caption">{{ item.id }}</span>
                  </template>

                  <template v-slot:item.active="{ item }">
                    <v-switch
                      :input-value="item.active"
                      inset
                      :true-value="1"
                      :false-value="0"
                      hide-details
                      @change="toggleClient(item)"
                      style="display: inline-block"
                      color="blue-grey lighten-1" />
                  </template>

                  <template v-slot:item.open="{ item }">
                    <v-btn-toggle mandatory background-color="blue-grey lighten-1" class="ml-2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :to="`/site/${item.id}`" small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on">
                            <v-icon small color="white">mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                        <span>View Site</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="updateDialog(item)" small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on">
                            <v-icon small color="white">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Site</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" @click="deleteDialog(item)">
                            <v-icon small color="white">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Scan</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </template>

                  <template v-slot:item.uptime="{ item }">
                    <div class="d-flex flex-row justify-center">
                      <div v-if="item.uptime == null || !item.active" :class="{ muted: !item.active }">
                        <b>NA</b>
                      </div>
                      <div class="py-2" v-else>
                        <div :class="[checkScore(item.uptime)]" style="line-height: 1em">
                          <b>{{ Math.floor(Math.abs(item.uptime)) }} %</b>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.risk_score="{ item }">
                    <div class="d-flex flex-row justify-center">
                      <div v-if="item.risk_score == null || !item.active" :class="{ muted: !item.active }">
                        <b>NA</b>
                      </div>
                      <div class="py-2" v-else>
                        <div :class="[checkScore(item.risk_score)]" style="line-height: 1em">
                          <b>{{ item.risk_score }}</b>
                        </div>
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" x-small plain text class="pa-0" fab>
                            <v-icon small class="pa-0">mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>Re-run</span>
                      </v-tooltip>
                    </div>
                  </template>

                </v-data-table>
              </div>

              <v-card class="d-flex flex-wrap" v-if="listType == 1" color="transparent" elevation="0">
                <v-card min-height="150px" v-for="(item, index) in buildClientList()" class="ma-1" width="32.3%" outlined :key="`card-` + index">
                  <v-card-text class="px-4 py-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab v-bind="attrs" v-on="on" dark small right absolute :to="`/site/${item.id}`">
                          <v-icon small>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>Open</span>
                    </v-tooltip>
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-h6 mb-1">
                          <div class="identity">
                            <div class="favicon">
                              <template v-if="!item.favicon">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" @click="rerunGetFavicon(item)" small plain text class="pa-0" fab>
                                      <v-icon color="white" class="pa-0">mdi-refresh</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Re-run Favicon</span>
                                </v-tooltip>
                              </template>
                              <v-img width="30px" height="30px" v-else dark class="rounded-circle" :src="item.favicon"></v-img>
                            </div>
                            <div class="name-url">
                              <b :class="{ muted: !item.active }">{{ item.name }}</b
                              ><br />
                              <a :href="item.url" target="_blank" class="text-caption">{{ item.url }} <v-icon x-small>mdi-open-in-new</v-icon></a>
                            </div>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Moment from "moment";

export default {
  name: "Sites.vue",
  data() {
    return {
      listType: 0,
      expanded: [],
      month: "all",
      showOnly: "all",
      showingOnlyFilter: [
        { id: "all", label: "All" },
        { id: "active", label: "All Active" },
        { id: "deactivated", label: "All Deactivated" },
      ],
      selectedIndex: 0,
      dialogInsert: false,
      dialogDelete: false,
      dialogUpdate: false,
      drawerSiteNotes: false,
    };
  },
  created() {
    this.month = this.$date.monthNumberByDate();
    if (localStorage.getItem("active-state") != null) {
      this.showOnly = localStorage.getItem("active-state");
    }
  },
  computed: {
    user() {
      return this.$store.getters["users/user"];
    },
    months() {
      let tmpMonths = this.$date.months();
      tmpMonths.forEach((element) => {
        if (element.id === Moment(new Date()).format("MM")) {
          element.label = element.label + " [Current]";
        }
      });
      return tmpMonths;
    },
    sites() {
      return this.$store.getters["sites/all"];
    },
    credits() {
      return this.$store.getters["transactions/credits"];
    },
    headers() {
      let ret = [
        { text: "", value: "icon", width: "40px" },
        { text: "Name", value: "name" },
        { text: "Frequency", value: "frequency", width: "100px", align: "center" },
        { text: "ID", value: "id", width: "80px", align: "center" },
        { text: "30 Days Uptime", value: "uptime", width: "130px", align: "center" },
        { text: "Risk Score", value: "risk_score", width: "130px", align: "center" },
        { text: "Active", value: "active", width: "80px", align: "center" },
        { text: "Edit", value: "open", sortable: false, width: "130px", align: "right" },
      ];
      return ret;
    },
  },
  methods: {
    getRowClass(item) {
      // Add the class 'highlight-row' if the status is 'inactive'
      return item.active === 1 ? '' : 'disabled';
    },
    checkScore(score) {
      switch (true) {
        case score > 0 && score < 50:
          return "red--text";
        case score > 50 && score < 75:
          return "orange--text";
        case score > 75 && score < 101:
          return "green--text";
        default:
          return "gray--text";
      }
    },
  
    async rerunGetFavicon(item) {
      await this.$store
        .dispatch("sites/updateFavicon", {
          id: item.id,
        })
        .then(() => {});
    },
    checkEvenOdd(date) {
      let selectedMonth = this.$date.monthNumberByDate(date);
      return selectedMonth % 2 == 0;
    },
    checkWithinQuarter(date) {
      let self = this;
      let startedMonth = this.$date.monthNumberByDate(date);
      let quarterMatrix = [
        ["01", "04", "07", "10"],
        ["02", "05", "08", "11"],
        ["03", "06", "09", "12"],
      ];
      for (let index = 0; index < quarterMatrix.length; index++) {
        const arr = quarterMatrix[index];
        if (arr.includes(startedMonth) && arr.includes(self.month)) {
          return true;
        }
      }
      return false;
    },
    filterClients(client) {
      switch (this.showOnly) {
        case "active":
          if (!client.active) {
            return false;
          }
          break;
        case "deactivated":
          if (client.active) {
            return false;
          }
          break;
      }
      switch (client.frequency) {
        case "Monthly":
          return true;
        case "Bi-Monthly":
          if (this.checkEvenOdd(client.started_on) == this.checkEvenOdd(self.month)) {
            return true;
          }
          return false;
        case "Quarterly":
          if (this.checkWithinQuarter(client.started_on)) {
            return true;
          }
          return false;
        default:
          return false;
      }
    },
    updateDialog (item) {
      this.selectedIndex = item.id;
      this.dialogUpdate = true;
    },
    deleteDialog(item) {
      this.selectedIndex = item.id;
      this.dialogDelete = true;
    },
    buildClientList() {
      return this.sites.filter(this.filterClients);
    },
    saveActiveState() {
      localStorage.setItem("active-state", this.showOnly);
    },
    async toggleClient(item) {
      await this.$store.dispatch("sites/toggle", { id: item.id }).then(() => {
        // item.active = (item.active == 1) ? 0 : 1;
      });
    },
  },
  watch: {
    month() {
      this.buildClientList();
    },
    sites(to, from) {
      if (to == from) return;
      this.buildClientList();
    },
  },
};
</script>

<style lang="scss">
  .v-data-table {
    .v-data-table__wrapper {
      .v-data-table__expanded.v-data-table__expanded__content {
        height: 70px;
      }
      table{
        tbody{
          tr{
            &.disabled{
              td{
                background-color: #f4f4f4;
                color: #c7c7c7;
                *{
                  color: #c7c7c7;
                }
                .main-icon{
                  color: transparent !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .identity {
    display: flex;
    flex-direction: row;
    .favicon {
      position: relative;
      z-index: 1;
      width: 32px;
      height: 32px;
      padding: 1px;
      border-radius: 50% !important;
      background: {
        size: cover;
        position: center;
        repeat: no-repeat;
        color: #ccc;
      }
      margin-right: 15px;
      display: flex;
      align-content: center;
      justify-content: center;
    }
    .name-url {
      line-height: 1em;
      b {
        font-size: 15px;
      }
    }
  }
</style>
